import { Header } from '@/utils/datatable/datatableInterface';
import DatatableIntetrface from '@/utils/datatable/datatableInterface'
import Datatable  from '@/utils/datatable/datatable'
const headers:Header[] = [
  {value:'Id', text: 'Id' , isPrice : false , isTotal:false , total:0},
  {value:'Name', text: 'Name' , isPrice : false , isTotal:false , total:0},
  {value:'NameAr', text: 'Arabic Name' , isPrice : false , isTotal:false , total:0},
]
const url = "services"

const params:DatatableIntetrface = {
    title : "services",
    headers ,
    description:"services_desc",
    searchable:true,
    url,
    hasFooter:false,
    hasEdit:false,
    hasCreate:false
}
const datatable = new Datatable(params)
export default datatable